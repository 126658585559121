import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { signOut } from 'next-auth/react';
import { useContext } from 'react';
import { track } from '../../../modules/segment/helpers';
import ReservationContext from '../../../providers/ReservationContext';
import getReservationTimeline from '../../../utils/reservation/getReservationTimeline';

const Links = ({ deltaEnabled, postMove, allowTip, meetTeamEnabled }) => {
  const reservation = useContext(ReservationContext);
  const timeline = getReservationTimeline(reservation);

  return [
    {
      href: '/',
      external: false,
      title: 'My dashboard',
      Icon: ChromeReaderModeOutlinedIcon,
    },
    {
      href: '/manage',
      external: false,
      title: 'Manage reservation',
      Icon: SettingsOutlinedIcon,
    },
    {
      href: '/account',
      external: false,
      title: 'Manage account',
      Icon: AccountCircleOutlinedIcon,
    },
    {
      href: '/resources',
      external: false,
      title: 'View helpful resources',
      Icon: HelpOutlineOutlinedIcon,
      callback: () => {
        track('Timeline - View Helpful Resources', { orderID: reservation?.orderCode, timeline });
      },
    },
    {
      href: '/contact',
      external: false,
      title: 'Contact support',
      Icon: PhoneInTalkOutlinedIcon,
    },
    deltaEnabled && !postMove
      ? {
          href: '/qrcode',
          external: false,
          title: 'Get your QR code',
          Icon: QrCodeScannerIcon,
        }
      : null,
    meetTeamEnabled
      ? {
          href: '/team',
          external: false,
          title: 'Meet your team',
          Icon: PeopleAltOutlinedIcon,
          callback: () => {
            track('Menu - Meet your team', { orderID: reservation?.orderCode, timeline });
          },
        }
      : null,
    {
      href: '/review',
      external: false,
      title: allowTip ? 'Tip and review your team' : 'Review your team',
      Icon: StarBorderOutlinedIcon,
      callback: () => {
        if (allowTip) {
          track('Timeline - Tip and Review team', { orderID: reservation?.orderCode, timeline });
        }
      },
    },
    {
      href: `${process.env.NEXT_PUBLIC_HELP_DESK_URL}`,
      external: true,
      title: 'Report an issue',
      Icon: HighlightOffOutlinedIcon,
    },
    {
      callback: () => signOut({ callbackUrl: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}` }),
      external: true,
      title: 'Log out',
      Icon: PowerSettingsNewOutlinedIcon,
    },
  ];
};

export default ({ permissions }) => Links(permissions).filter((link) => link !== null);
