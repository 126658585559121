import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { node } from 'prop-types';
import { useSession } from 'next-auth/react';

import fetchOrderRequireContracts from '../api/queries/fetchOrderRequireContracts';
import fetchContracts from '../api/queries/fetchContracts';

export const SystemContext = createContext({});

const SystemContextProvider = ({ children }) => {
  const { data: session } = useSession();

  const [userMessages, setUserMessages] = useState([]);

  const { data: contractsRequired, isLoading: isContractsRequiredLoading } = useQuery(
    ['contractsRequired', session?.user.reservationID],
    fetchOrderRequireContracts,
    {
      staleTime: Infinity,
      useErrorBoundary: true,
      enabled: Boolean(session),
    },
  );

  const {
    data: contractsData,
    refetch: refetchContracts,
    isLoading: isContractsLoading,
  } = useQuery(['contracts', session?.user?.reservationID], fetchContracts, {
    staleTime: Infinity,
    useErrorBoundary: true,
    enabled: Boolean(contractsRequired), // TODO: enable only when new endpoint (contracts valid for order)
  });

  const [progressActionStates, setProgressActionStates] = useState({
    preMoveContract: {},
    postMoveContract: {},
  });

  // Manage Progress Action States
  const setProgressActionState = (progressActions) => {
    const newProgressActionStates = { ...progressActionStates };
    progressActions.forEach((action) => {
      const [progressAction, state, value] = action;
      newProgressActionStates[progressAction] = {
        ...newProgressActionStates[progressAction],
        [state]: value,
      };
    });
    setProgressActionStates(newProgressActionStates);
  };

  // Manage User Messaging
  const addUserMessage = (config) => {
    const exists = userMessages.filter((i) => i.id === config.id).length > 0;

    if (!exists) {
      setUserMessages([...userMessages, { ...config, id: config.id ? config.id : crypto.randomUUID() }]);
    }
  };

  const removeUserMessage = (config) => {
    setUserMessages(userMessages.filter((i) => i.id !== config.id));
  };

  useEffect(() => {
    if (contractsRequired && contractsData !== null) {
      setProgressActionStates({
        preMoveContract: { required: true },
        postMoveContract: { required: true },
      });
    } else {
      setProgressActionStates({
        preMoveContract: { required: false },
        postMoveContract: { required: false },
      });
    }
  }, [contractsRequired, contractsData]);

  useEffect(() => {
    // TODO: Set user messages based on outcome
    if (contractsData !== null) {
      if (contractsData?.contract_type === 'PRE_MOVE') {
        // Pre move contract returned means no post move exists, set post move signed to false always
        if (contractsData.contract_approved) {
          setProgressActionState([
            ['preMoveContract', 'signed', true],
            ['postMoveContract', 'signed', false],
          ]);
        } else {
          setProgressActionState([
            ['preMoveContract', 'signed', false],
            ['postMoveContract', 'signed', false],
          ]);
        }
      } else if (contractsData?.contract_type === 'POST_MOVE') {
        // Post move contract exists, assume pre move is signed
        if (contractsData.contract_approved) {
          setProgressActionState([
            ['preMoveContract', 'signed', true],
            ['postMoveContract', 'signed', true],
          ]);
        } else {
          setProgressActionState([
            ['preMoveContract', 'signed', true],
            ['postMoveContract', 'signed', false],
          ]);
        }
      }
    } else {
      // No contract data means none exist, assume neither signed
      setProgressActionState([
        ['preMoveContract', 'signed', false],
        ['postMoveContract', 'signed', false],
      ]);
    }
  }, [contractsData]);

  const value = useMemo(
    () => ({
      contractsData,
      contractsRequired,
      isContractsRequiredLoading,
      isContractsLoading,
      userMessages,
      refetchContracts,
      addUserMessage,
      removeUserMessage,
      progressActionStates,
    }),
    [contractsData, contractsRequired, isContractsRequiredLoading, isContractsLoading, userMessages, progressActionStates],
  );

  return <SystemContext.Provider value={value}>{children}</SystemContext.Provider>;
};

SystemContextProvider.propTypes = {
  children: node.isRequired,
};

export default SystemContextProvider;
