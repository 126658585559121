import { getSession } from 'next-auth/react';
import fetchAPI from '../fetchAPI';

export default async () => {
  const session = await getSession();
  if (session?.user?.getReservationToken?.jwt) {
    const contractStateQuery = `
      query contractsRequired {
        orderRequireContracts {
          value
        }
      }
    `;

    const data = await fetchAPI({
      query: contractStateQuery,
      headers: {
        Authorization: `Bearer ${session.user.getReservationToken.jwt}`,
      },
    });

    return data?.orderRequireContracts?.value;
  }
  return {};
};
