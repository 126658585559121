import { getSession } from 'next-auth/react';
import fetchAPI from '../fetchAPI';

export default async (orderCode) => {
  const session = await getSession();
  if (session?.user?.getReservationToken?.jwt) {
    const orderQuery = `
    query getOrder($id: String!) {
      order(id: $id) {
        isMinimum
        balance {
          outstanding {
            amount
            currency
          }
        }
        subtotal {
          amount
          currency
        }
        total {
          amount
          currency
        }
        breakdown {
          orderID
          jobs {
            jobID
            jobSlots {
              jobSlotID
              clockInDatetime
              clockInTimezone
              clockOutDatetime
              clockOutTimezone
              hourlyRate {
                amount
                currency
              }
              subtotal {
                amount
                currency
              }
            }
            billableMinimumHours
            isMinimum
            pricingStrategy
            subtotal {
              amount
              currency
            }
          }
          products {
            code
            value {
              amount
              currency
            }
          }
          fees {
            code
            value {
              amount
              currency
            }
          }
          reductions {
            code
            value {
              amount
              currency
            }
          }
          tips {
            code
            value {
              amount
              currency
            }
          }
          subtotal {
            amount
            currency
          }
        }
      }
    }`;

    const data = await fetchAPI({
      query: orderQuery,
      variables: {
        id: orderCode,
      },
      headers: {
        Authorization: `Bearer ${session.user.getReservationToken.jwt}`,
      },
    });

    return { order: data };
  }
  return { order: null };
};
