import React, { useState, useContext } from 'react';
import NextLink from 'next/link';
import { useSession } from 'next-auth/react';
import { AppBar, Box, Button, IconButton } from '@mui/material';
import Menu from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from './Drawer';
import Link from '../Link';
import PHONE_NUMBERS from '../../constants/phoneNumbers';
import ReservationContext from '../../providers/ReservationContext';
import VerticalDivider from '../ReservationDetailsDesktop/VerticalDivider';

const Header = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const { data: session } = useSession();

  const [menuOpen, setMenuOpen] = useState(false);

  const { partner } = useContext(ReservationContext);

  return (
    <Box displayPrint='none'>
      <AppBar
        position='sticky'
        color='text'
        elevation={0}
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: theme.palette.text.primary,
          borderBottom: `4px solid ${theme.palette.primary.main}`,
        }}
      >
        <Box
          height={{ xs: 68, md: 96 }}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          width='100%'
          margin='0 auto'
          px={{ xs: 2, md: 5 }}
        >
          {/* Logo */}
          <Box display='flex' flexGrow={1} alignItems='center' justifyContent='flex-start' sx={{ height: 35 }}>
            <Link
              display='flex'
              href={session ? '/' : process.env.NEXT_PUBLIC_BOOKING_SITE_URL}
              external={!session}
              target='_self'
            >
              <img
                width={mobile ? 38 : 150}
                height={mobile ? 29 : 35}
                loading='lazy'
                src={mobile ? '/svgs/LogoMobile.svg' : '/svgs/LogoDesktop.svg'}
                alt='Bellhop Logo'
              />
            </Link>
            {partner?.cobrand && (
              <>
                <Box px={3} height={45} overflow='hidden'>
                  <VerticalDivider />
                </Box>
                <img
                  height={mobile ? 29 : 35}
                  loading='lazy'
                  src={
                    mobile
                      ? `/svgs/cobranding/${partner.code}LogoMobile.svg`
                      : `/svgs/cobranding/${partner.code}LogoDesktop.svg`
                  }
                  alt={`${partner.code} Logo`}
                />
              </>
            )}
          </Box>
          {/* Hamburger Menu */}
          {session ? (
            <Box display='flex'>
              <Box display={{ xs: 'none', md: 'flex' }}>
                <NextLink href='/contact' passHref>
                  <IconButton sx={{ ml: 1 }}>
                    <WifiCalling3Icon sx={{ color: theme.palette.neutral.lightest }} />
                  </IconButton>
                </NextLink>
                <Box width='1px' height={40} backgroundColor='neutral.lightest' mx={2} />
              </Box>
              <IconButton onClick={() => setMenuOpen(!menuOpen)} aria-label='hamburger menu'>
                {menuOpen ? (
                  <Close sx={{ height: 36, width: 36, color: theme.palette.neutral.lightest }} />
                ) : (
                  <Menu sx={{ height: 36, width: 36, color: theme.palette.neutral.lightest }} />
                )}
              </IconButton>
            </Box>
          ) : (
            <Box display='flex'>
              <Box display={{ xs: 'none', md: 'flex' }}>
                <Link href={`tel:${PHONE_NUMBERS.CUSTOMER_SERVICE}`} external>
                  <Box ml={1} p={1}>
                    <WifiCalling3Icon sx={{ color: theme.palette.neutral.lightest }} />
                  </Box>
                </Link>
                <Box width='1px' height={40} backgroundColor='neutral.lightest' mx={2} />
              </Box>
              <Button
                variant='outlined'
                sx={{
                  color: theme.palette.neutral.lightest,
                  '&:hover': {
                    background: '#2C3B5F',
                  },
                }}
                href={`${process.env.NEXT_PUBLIC_BOOKING_SITE_URL}/book/service`}
                external
              >
                Book a move
              </Button>
            </Box>
          )}
        </Box>
        {session && menuOpen && <Drawer drawerOpen={menuOpen} setDrawerOpen={setMenuOpen} />}
      </AppBar>
    </Box>
  );
};

export default Header;
