import React, { forwardRef } from 'react';
import { bool, node, string } from 'prop-types';
import NextLink from 'next/link';
import { Link as MuiLink } from '@mui/material';

const Link = forwardRef(({ href, children, external, ...props }, ref) => {
  const tel = href?.startsWith('tel:');
  return external ? (
    <MuiLink
      ref={ref}
      href={href}
      fontFamily='CalibreMedium'
      underline='none'
      target={tel ? '_self' : '_blank'}
      rel='noreferrer'
      {...props}
    >
      {children}
    </MuiLink>
  ) : (
    <MuiLink
      ref={ref}
      fontFamily='CalibreMedium'
      underline='none'
      {...props}
      component={NextLink}
      href={{
        pathname: href,
        query: typeof window !== 'undefined' && window.location.search.replace('?', ''),
      }}
      passHref
    >
      {children}
    </MuiLink>
  );
});

Link.propTypes = {
  href: string,
  children: node.isRequired,
  external: bool,
};

Link.defaultProps = {
  external: false,
  href: null,
};

export default Link;
