import { getSession } from 'next-auth/react';
import fetchAPI from '../fetchAPI';

export default async (orderCode) => {
  const session = await getSession();
  if (session?.user?.getReservationToken?.jwt) {
    const estimatedOrderQuery = `
    query getOrder($id: String!) {
      order(id: $id) {
        estimatedBalance {
          outstanding {
            amount
            currency
          }
        }
        estimatedSubtotal {
          amount
          currency
        }
        estimatedTotal {
          amount
          currency
        }
      }
    }`;

    const data = await fetchAPI({
      query: estimatedOrderQuery,
      variables: {
        id: orderCode,
      },
      headers: {
        Authorization: `Bearer ${session.user.getReservationToken.jwt}`,
      },
    });

    return { order: data };
  }
  return { order: null };
};
